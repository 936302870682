export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function() {
            /* eslint-disable */
            FB.init({
              appId      : process.env.VUE_APP_FB_APP_ID,
              cookie     : true,
              autoLogAppEvents : true,
              xfbml      : true,
              xfbml      : true,
              version    : 'v9.0'
            });
            resolve();
            // auto authenticate with the api if already logged in with facebook
            /* eslint-disable */
            // FB.getLoginStatus(({ authResponse }) => {
            //     console.log('getLoginStatus');
            //     console.log(authResponse);
            //     resolve();
            // });
        };

        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk')); 
    });
}

export function serializeErrorMessage (error) {
  console.error(error)
  if (typeof error.response !== 'undefined') {
    if (typeof error.response.data.errors !== 'undefined') {
      let validationMessages = error.response.data.errors
      if (typeof validationMessages === 'string') {
        return validationMessages
      } else if (Object.keys(validationMessages).length) {
        let errorMessages = '<ul>'
        for (let i = 0; i < Object.keys(validationMessages).length; i++) {
          errorMessages = errorMessages + '<li>' + validationMessages[Object.keys(validationMessages)[i]] + '</li>'
        }
        errorMessages = errorMessages + '</ul>'
        return errorMessages
      } else {
        return error.response.data
      }
    } else if (typeof error.response.data.message !== 'undefined') {
      return error.response.data.message === 'The user credentials were incorrect.' || error.response.data.message === 'Client authentication failed' ? 'Kombinasi email/username & password salah' : error.response.data.message
    } else if (typeof error.response.data !== 'undefined') {
      return error.response.data
    }
  } else if (typeof error === 'string') {
    return error
  } else {
    return 'something wrong, silahkan coba kembali.'
  }
}

export function goBack (self) {
  if (self.$route.query.redirect !== undefined) {
    self.$router.push(self.$route.query.redirect)
  } else {
    window.history.back()
  }
}

export function commentSeparateByList () {
  return [
    { text: 'Spasi', value: 'space'},
    { text: 'Koma', value: ','},
    { text: 'Titik', value: '.'},
    { text: 'Pagar', value: '#'},
    { text: 'Bintang', value: '*'},
    { text: 'Garis Penghubung', value: '-'}
  ]
}


export function commentFormatList () {
  return [
    { text: 'Kode Produk', value: 'sku_id', replaceWord: 'R01'},
    // { text: 'Ukuran', value: 'product_size', replaceWord: 'S6'},
    { text: 'No HP', value: 'phone_number', replaceWord: '082362216649'},
    // { text: 'Kode Pos', value: 'zip_code', replaceWord: '20118'},
    { text: 'Qty', value: 'quantity', replaceWord: '12'}
  ]
}