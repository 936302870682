import Vue from 'vue'
import App from '@/App'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import router from './router'
import moment from 'moment-timezone'
import swal from 'sweetalert2'
// import 'es6-promise/auto'
import store from './store'
// import { initFacebookSdk } from './_helpers'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, faSearch, faUserTie,faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faClipboard, faInfoCircle, faImage, faFileAlt, faSearchPlus, faCreditCard, faEyeSlash, faArchive, faCopyright, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faTags, faBuilding, faPencilAlt, faVideo, faPaperclip, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
import { faFacebook as fabFacebook, faProductHunt as fabProductHunt } from '@fortawesome/free-brands-svg-icons'
import config from '@/admin-config.json'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import ToggleButton from 'vue-js-toggle-button'

library.add(faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, fabFacebook, faSearch, faUserTie,faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faImage, faClipboard, faInfoCircle, faFileAlt, faSearchPlus, faCreditCard, faEye, faEyeSlash, faArchive, faCopyright, fabProductHunt, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faTags, faBuilding, faPencilAlt, faVideo, faPaperclip, faDownload, faPrint)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Loading)
Vue.use(ToggleButton)

moment.tz.setDefault('Asia/Jakarta')
moment.locale('id-ID')
Vue.prototype.$webConfig = config
Vue.prototype.$moment = moment
Vue.prototype.$http = axios
Vue.prototype.$swal = swal
Vue.prototype.$formatPrice = (value) => {
  if (value === null) {
    return 0
  }
  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: (value % 1 > 0 ? 2 : 0)}).format(value)
}

Vue.prototype.$capitalizeFirstLetter = (string) => {
  return string === null ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')