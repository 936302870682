import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/NotFoundPage')
const Login = () => import(/* webpackChunkName: "Login" */ '@/pages/Auth/Login.vue')
const Logout = () => import(/* webpackChunkName: "Logout" */ '@/pages/Auth/Logout.vue')
const GeneralScreenDashboard = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/GeneralScreenDashboard.vue')
const EmptyContent = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/EmptyContent.vue')
const Home = () => import(/* webpackChunkName: "Home" */ '@/pages/Dashboard/Home.vue')
const SettingPassword = () => import(/* webpackChunkName: "Home" */ '@/pages/Settings/Password.vue')
const ProductCategoryList = () => import(/* webpackChunkName: "User" */ '@/pages/ProductCategory/List.vue')
const ProductCategoryForm = () => import(/* webpackChunkName: "User" */ '@/pages/ProductCategory/Form.vue')
const ShowProductCategory = () => import(/* webpackChunkName: "User" */ '@/pages/ProductCategory/Detail.vue')
const CompanyList = () => import(/* webpackChunkName: "User" */ '@/pages/Company/List.vue')
const CompanyForm = () => import(/* webpackChunkName: "User" */ '@/pages/Company/Form.vue')
const OrderGroupList = () => import(/* webpackChunkName: "User" */ '@/pages/OrderGroup/List.vue')
const OrderGroupShow = () => import(/* webpackChunkName: "User" */ '@/pages/OrderGroup/Detail.vue')
const ShowCompany = () => import(/* webpackChunkName: "User" */ '@/pages/Company/Detail.vue')
const CustomerList = () => import(/* webpackChunkName: "User" */ '@/pages/Customer/List.vue')
const CustomerForm = () => import(/* webpackChunkName: "User" */ '@/pages/Customer/Form.vue')
const ShowCustomer = () => import(/* webpackChunkName: "User" */ '@/pages/Customer/Detail.vue')
const OrderList = () => import(/* webpackChunkName: "User" */ '@/pages/Order/List.vue')
const OrderForm = () => import(/* webpackChunkName: "User" */ '@/pages/Order/Form.vue')
const ShowOrder = () => import(/* webpackChunkName: "User" */ '@/pages/Order/Detail.vue')
const InvoiceOrder = () => import(/* webpackChunkName: "User" */ '@/pages/Order/Invoice.vue')
const ProductList = () => import(/* webpackChunkName: "User" */ '@/pages/Product/List.vue')
const ProductForm = () => import(/* webpackChunkName: "User" */ '@/pages/Product/Form.vue')
const ShowProduct = () => import(/* webpackChunkName: "User" */ '@/pages/Product/Detail.vue')
const BankAccountList = () => import(/* webpackChunkName: "User" */ '@/pages/BankAccount/List.vue')
const BankAccountForm = () => import(/* webpackChunkName: "User" */ '@/pages/BankAccount/Form.vue')
const ShowBankAccount = () => import(/* webpackChunkName: "User" */ '@/pages/BankAccount/Detail.vue')
const OrderReportList = () => import(/* webpackChunkName: "User" */ '@/pages/Report/Order/List.vue')
const UserList = () => import(/* webpackChunkName: "User" */ '@/pages/User/List.vue')
const UserForm = () => import(/* webpackChunkName: "User" */ '@/pages/User/Form.vue')
const ShowUser = () => import(/* webpackChunkName: "User" */ '@/pages/User/Detail.vue')
// const WebConfigs = () => import(/* webpackChunkName: "User" */ '@/pages/WebConfigs/WebConfigs.vue')
const CommentFormatWebConfig = () => import(/* webpackChunkName: "User" */ '@/pages/WebConfigs/CommentFormat.vue')
const WhatsAppWebConfig = () => import(/* webpackChunkName: "User" */ '@/pages/WebConfigs/WhatsApp.vue')

const routes = [
    {
      path: '/logout',
      component: Logout,
      meta: { requiresAuth: true }
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      component: GeneralScreenDashboard,
      children: [
        {
          path: 'login',
          name: 'Login Account',
          component: Login
        }
      ],
      meta: { requiresGuest: true }
    },
    {
      path: '/orders/:id/invoice',
      component: InvoiceOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/',
      component: GeneralScreenDashboard,
      children: [
        {
          // path: 'index',
          path: '/',
          name: 'Home',
          redirect: 'order-groups',
          component: Home
        },
        {
          path: 'settings',
          name: 'Empty Content Setting Container',
          component: EmptyContent,
          children: [
            {
              path: 'password',
              name: 'Setting Password',
              component: SettingPassword
            }
          ]
        },
        {
          path: 'orders',
          name: 'Empty Content Orders Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Order',
              component: OrderList
            },
            {
              path: 'create',
              name: 'Tambah Order',
              component: OrderForm
            },
            {
              path: ':id/edit',
              name: 'Edit Order',
              component: OrderForm
            },
            {
              path: ':id',
              name: 'Detail Order',
              component: ShowOrder
            }
          ]
        },
        {
          path: '/report/orders',
          name: 'Empty Content Order Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Laporan Order',
              component: OrderReportList
            }
          ]
        },
        {
          path: 'product-categories',
          name: 'Empty Content Product Category Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Kategori Produk',
              component: ProductCategoryList
            },
            {
              path: 'create',
              name: 'Tambah Kategori Produk',
              component: ProductCategoryForm
            },
            {
              path: ':id/edit',
              name: 'Edit Kategori Produk',
              component: ProductCategoryForm
            },
            {
              path: ':id',
              name: 'Detail Kategori Produk',
              component: ShowProductCategory
            }
          ]
        },
        // {
        //   path: 'companies',
        //   name: 'Empty Content Company Container',
        //   component: EmptyContent,
        //   children: [
        //     {
        //       path: 'index',
        //       alias: '/',
        //       name: 'Daftar Company',
        //       component: CompanyList
        //     },
        //     {
        //       path: 'create',
        //       name: 'Tambah Company',
        //       component: CompanyForm
        //     },
        //     {
        //       path: ':id/edit',
        //       name: 'Edit Company',
        //       component: CompanyForm
        //     },
        //     {
        //       path: ':id',
        //       name: 'Detail Company',
        //       component: ShowCompany
        //     }
        //   ]
        // },
        {
          path: 'order-groups',
          name: 'Empty Content Order Group Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Sesi Live',
              component: OrderGroupList
            },
            {
              path: ':id',
              name: 'Detail Sesi Live',
              component: OrderGroupShow
            }
          ]
        },
        {
          path: 'customers',
          name: 'Empty Content Customer Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Customer',
              component: CustomerList
            },
            {
              path: 'create',
              name: 'Tambah Customer',
              component: CustomerForm
            },
            {
              path: ':id/edit',
              name: 'Edit Customer',
              component: CustomerForm
            },
            {
              path: ':id',
              name: 'Detail Customer',
              component: ShowCustomer
            }
          ]
        },
        {
          path: 'products',
          name: 'Empty Content Product Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Produk',
              component: ProductList
            },
            {
              path: 'create',
              name: 'Tambah Produk',
              component: ProductForm
            },
            {
              path: ':id/edit',
              name: 'Edit Produk',
              component: ProductForm
            },
            {
              path: ':id',
              name: 'Detail Produk',
              component: ShowProduct
            }
          ]
        },
        {
          path: 'bank-accounts',
          name: 'Empty Content Bank Account Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Akun Bank',
              component: BankAccountList
            },
            {
              path: 'create',
              name: 'Tambah Akun Bank',
              component: BankAccountForm
            },
            {
              path: ':id/edit',
              name: 'Edit Akun Bank',
              component: BankAccountForm
            },
            {
              path: ':id',
              name: 'Detail Akun Bank',
              component: ShowBankAccount
            }
          ]
        },
        {
          path: 'users',
          name: 'Empty Content Staff Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Daftar Staff',
              component: UserList
            },
            {
              path: 'create',
              name: 'Tambah Staff',
              component: UserForm
            },
            {
              path: ':id/edit',
              name: 'Edit Staff',
              component: UserForm
            },
            {
              path: ':id',
              name: 'Detail Staff',
              component: ShowUser
            }
          ]
        },

        {
          path: 'web-configs',
          name: 'Empty Content Web Concif Container',
          component: EmptyContent,
          redirect: 'web-configs/comment-format',
          children: [
            // {
            //   path: 'index',
            //   alias: '/',
            //   name: 'Daftar Staff',
            //   component: UserList
            // },
            // {
            //   path: 'create',
            //   name: 'Tambah Staff',
            //   component: UserForm
            // },
            // {
            //   path: ':id/edit',
            //   name: 'Edit Staff',
            //   component: UserForm
            // },
            {
              path: 'comment-format',
              name: 'Comment Format',
              component: CommentFormatWebConfig
            },
            {
              path: 'whats-app',
              name: 'WhatsApp',
              component: WhatsAppWebConfig
            }
          ]
        }
      ],
      meta: { requiresAuth: true }
    }, {
      path: '*', component: NotFound
    }
  ]
  
  Vue.use(VueRouter)
  const router = new VueRouter({
    routes, // short for router: router
    linkActiveClass: 'active'
  })
  
  if (store.getters['account/isLoggedIn']) {
    store.dispatch('account/saveTokens', {tokens: store.getters['account/tokens'], accountData: null, isLocalLogin: true})
  }
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters['account/isLoggedIn']) {
        next({
          path: '/auth/login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
      if (store.getters['account/isLoggedIn']) {
        next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })
  
  export default router